<template>
    <!-- 教学教研 > 课程表 >  校历管理 -->
    <!-- <div>校历管理</div> -->
    <teaching-research-school-calendar-management-scrollWrapper></teaching-research-school-calendar-management-scrollWrapper>
</template>
<script>
import TeachingResearchSchoolCalendarManagementScrollWrapper from "@/components/scrollWrapper/TeachingResearchSchoolCalendarManagementScrollWrapper";
export default {
    name: "TeachingResearchSchoolCalendarManagement",
    components: {
        TeachingResearchSchoolCalendarManagementScrollWrapper
    },
}
</script>
