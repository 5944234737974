<template>
    <div class="ui">
        <h2>按钮</h2>
        <div>
      <div class="title-top">
        <h3>高权重按钮默认</h3>
        <hr />
        <h5>移入点击</h5>
        <xk-button>默认</xk-button>
        <xk-button type="primary">添加</xk-button>
        <xk-button type="success">修改</xk-button>
        <xk-button type="danger">删除</xk-button>
        <xk-button type="primary">查询</xk-button>
        <xk-button type="info">重置</xk-button>
        <xk-button type="enquiry">重置</xk-button>
        <xk-button type="warning">批量操作</xk-button>
        <h5>不可用状态</h5>
        <xk-button disabled>默认</xk-button>
        <xk-button type="primary" disabled>添加</xk-button>
        <xk-button type="success" disabled>修改</xk-button>
        <xk-button type="danger" disabled>删除</xk-button>
        <xk-button type="primary" disabled>查询</xk-button>
        <xk-button type="info" disabled>重置</xk-button>
        <xk-button type="warning" disabled>批量操作</xk-button>
      </div>

      <div class="title-top">
        <h3>文字按钮</h3>
        <hr />
        <h5>移入点击</h5>
        <xk-button text>文字默认</xk-button>
        <xk-button type="primary" text>添加</xk-button>
        <xk-button type="success" text>修改</xk-button>
        <xk-button type="danger" text>删除</xk-button>
        <xk-button type="primary" text>查询</xk-button>
        <xk-button type="success" text>下载</xk-button>
        <xk-button type="info" text>修改密码</xk-button>
        <xk-button type="primary" text>详情</xk-button>
        <h5>不可用状态</h5>
        <xk-button text disabled>文字默认</xk-button>
        <xk-button type="primary" text disabled>添加</xk-button>
        <xk-button type="success" text disabled>修改</xk-button>
        <xk-button type="danger" text disabled>删除</xk-button>
        <xk-button type="primary" text disabled>查询</xk-button>
        <xk-button type="success" text disabled>下载</xk-button>
        <xk-button type="info" text disabled>修改密码</xk-button>
        <xk-button type="primary" text disabled>详情</xk-button>
      </div>

      <div class="title-top">
        <h3>表格按钮</h3>
        <hr />
        <h5>移入点击</h5>
        <xk-button size="mini">默认</xk-button>
        <xk-button type="primary" size="mini">校园后台</xk-button>
        <xk-button type="success" size="mini">编辑</xk-button>
        <xk-button type="warning" size="mini">重置密码</xk-button>
        <xk-button type="danger" size="mini">删除</xk-button>
        <h5>不可用状态</h5>
        <xk-button size="mini" disabled>默认</xk-button>
        <xk-button type="primary" size="mini" disabled>校园后台</xk-button>
        <xk-button type="success" size="mini" disabled>编辑</xk-button>
        <xk-button type="warning" size="mini" disabled>重置密码</xk-button>
        <xk-button type="danger" size="mini" disabled>删除</xk-button>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    name: "UI"
}
</script>
<style lang="scss">
  .title-top {
    margin-top: 30px;
  }
</style>