<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-11-18 08:44:52
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-11-26 11:37:07
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/views/TeachingResearch.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template> 
    <!-- 教学教研 -->
    <KeepAliveRouterView />
</template>

<script>
export default {
    name: "TeachingResearch"
}
</script>

<style scoped>

</style>
