<template>
    <!-- 教学教研 > 课程表 >  课程表 -->
    <teaching-research-course-schedule-temp-scroll-wrapper>课程表</teaching-research-course-schedule-temp-scroll-wrapper>
</template>
<script>
import TeachingResearchCourseScheduleTempScrollWrapper from '@/components/scrollWrapper/TeachingResearchCourseScheduleTemp.vue'
export default {
    name: "TeachingResearchCourseScheduleTemp",
    components: {
        TeachingResearchCourseScheduleTempScrollWrapper
    }

}
</script>