<template>
    <teacher-leave-record-scroll-wrapper></teacher-leave-record-scroll-wrapper>
</template>

<script>
import TeacherLeaveRecordScrollWrapper from "@/components/scrollWrapper/TeacherLeaveRecord.vue"
export default {
    name: "TeacherLeaveRecord",
    components: {
        TeacherLeaveRecordScrollWrapper
    }
};
</script>