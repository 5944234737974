<template>
    <div></div>
</template>
<script>
import { CommonModel } from '@/models/Common.js'
import { removeToken, setToken, getToken } from "@/libs/auth";
export default {
    name: "ThirdPartyJump",
    data() {
        return {
            routingQuery: {
                "userId": '',
                "userName": '',
                "schoolId": ''
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.initAssignment();
            this.initPageData();
        },
        initAssignment() {
            removeToken()
            this.routingQuery = this.$route.query;
            console.log(this.routingQuery);
        },
        initPageData() {
            const commonModel = new CommonModel();
            commonModel.autoLoginSchoolFromOldOperate(this.routingQuery).then((res) => {
                if (res.data.code == '200') {
                    setToken(res.data.data.token)
                    setTimeout(() => {
                        this.jumpPath(res.data.data.projectMark)
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                    this.$router.push('/login')
                }
            })
        },
        jumpPath(projectMark) {
            this.$router.push({
                path: '/firstPage',
                query: {
                    projectMark: projectMark
                }
            })
        }
    },
};
</script>
