<template>
    <!-- 教学教研 > 课堂行为 > 课堂观察记录  -->
    <teaching-research-classroom-observation-records-scroll-wrapper></teaching-research-classroom-observation-records-scroll-wrapper>
</template>
<script>
import TeachingResearchClassroomObservationRecordsScrollWrapper from '@/components/scrollWrapper/TeachingResearchClassroomObservationRecords.vue';

export default {
    name: "TeachingResearchClassroomObservationRecords",
    components: {
        TeachingResearchClassroomObservationRecordsScrollWrapper
    }
}
</script>