<template>
    <!-- 教学教研 > 课堂行为 -->
    <KeepAliveRouterView />
  </template>
  
  <script>
  export default {
    name: "TeachingResearchClassroomBehavior"
  }
  </script>
  
  <style scoped>
  
  </style>
  