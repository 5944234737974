<template>
    <div class="test">
        <button @click="open">click</button>
        <xk-tree-shape
            v-if="show"
            :show="show"
            :list="list"
            :selectData="selectData"
            @getNextLevelTreeList="getNextLevelTreeList"
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </xk-tree-shape>
    </div>
</template>
<script>
import {CommonModel} from "@/models/Common.js";
import {mapState} from "vuex";
export default {
    name: "Test",
    data() {
        return {
            show: false,
            req: {
                schoolId: "",
                type: "student,organ",
                parentId: "",
                name: "",
                organType: "2,3,4,5",
            },
            // 已选中数据
            selectData: [],
            // 接口返回数据
            list: [],
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    methods: {
        init() {
            this.req.schoolId = this.schoolId;

            this.getList();
        },
        async getList() {
            const commonModel = new CommonModel();
            await commonModel.getCommonUserList(this.req).then((res) => {
                if (res.data.code == "200") {
                    this.list = res.data.data;
                    console.log(this.list, "请求list数据");
                } else if (res.data && res.data.msg) {
                    console.log(res.data.msg, "error");
                }
            });
        },
        open() {
            this.show = true;
        },
        async getNextLevelTreeList(data) {
            const {id, name} = data;
            this.req.parentId = id || null;
            this.req.name = name;
            await this.getList();
            this.$eventBroadcast("getNewTreeList");
            console.log(data, "请求完成");
        },
        determine(data) {
            console.log(data, "结果");
        },
        closeDialog() {
            this.show = false;
        },
    },
};
</script>
