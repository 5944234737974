<!-- 智慧教学-课程管理-课时统计 -->
<template>
    <class-hour-statistics></class-hour-statistics>
</template>
<script>
import ClassHourStatistics from "@/components/scrollWrapper/ClassHourStatistics";
export default {
    name: "TeachingResearchCourseLessonTimeStatistics",
    components: {
        ClassHourStatistics
    },
}
</script>
