<template>
    <transit-scroll-wrapper></transit-scroll-wrapper>
</template>
<script>
import TransitScrollWrapper from '@/components/scrollWrapper/Transit.vue';
export default {
    name: "Transit",
    components: {
        TransitScrollWrapper
    }
}
</script>
