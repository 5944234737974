<template>
    <div>
        <theme-color-wrapper></theme-color-wrapper>
    </div>
</template>
<script>

    import ThemeColorWrapper from '@/components/scrollWrapper/ThemeColor.vue';
    export default {
        name: 'ThemeColor',
        components: {
            ThemeColorWrapper
        }
    }
</script>