<template>
    <!-- 教学教研 > 课堂行为 >  行为标签 -->
    <behavior-label></behavior-label>
</template>
<script>
import BehaviorLabel from "@/components/scrollWrapper/BehaviorLabel/index"
export default {
    name: "TeachingResearchBehaviorTags",
    components:{
        BehaviorLabel
    }
}
</script>
