<template>
  <!-- 教学教研 / 教研分析 / 会议分析 -->
  <meeting-list @setPageInitTabs="setPageInitTabs" v-if="meetingId === ''" @switchPage="switchPage"></meeting-list>
  <meeting-info @setPageInitTabs="setPageInitTabs" v-else-if="meetingId !== ''" :meetingId="meetingId" @switchPage="switchPage"></meeting-info>
</template>

<script>
import MeetingList from "../components/scrollWrapper/MeetingList"
import MeetingInfo from "../components/scrollWrapper/MeetingInfo"
export default {
  components: { MeetingList, MeetingInfo },
  data() {
    return {
      meetingId: ""
    }
  },
  methods: {
    /**
     * @Description: 切换组件
     * @DoWhat: 接收子组件传递的会议 ID 参数，并赋值到 meetingId
     * @UseScenarios: 会议列表和详情组件切换时使用
     * @Attention:
     * @Author: 马枫茂
     * @Date: 2022-11-08 16:00:13
     */
    switchPage(page) {
      this.meetingId = page
    },
    setPageInitTabs(){
        this.meetingId = ''
        this.$eventDispatch('setGlobalPageType', 'list')
    }
  }
}
</script>

<style scoped>

</style>
