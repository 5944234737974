<template>
    <!-- 教学教研 > 课程表 >  作息时间表 -->
    <daily-schedule></daily-schedule>
</template>
<script>
import DailySchedule from "@/components/scrollWrapper/TimeTable/DailySchedule";
export default {
    name: "TeachingResearchWorkSchedule",
    components: {
        DailySchedule
    }
}
</script>
