<template> 
    <!-- 教学教研 / 教研分析 -->
    <KeepAliveRouterView />
</template>

<script>
export default {
    name: "TeachingResearchAnalysis"
}
</script>

<style scoped>

</style>